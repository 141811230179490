<template>
  <v-container class="fill-height pt-md-10">
    <v-row class="text-center" align="start" justify="center">
      <v-col cols="12" md="8" class="mb-2" align-self="start">
        <h2 class="header font-weight-bold mt-3 white--text">
          AVISOS
        </h2>

        <v-spacer></v-spacer>

        <p class="title-2 font-weight-bold mb-5 dark white--text">
          Aquí pots veure els avisos i incidències generals.
        </p>
        <p class="body-1 mb-5 dark white--text">
          També pots rebre avisos específics sobre les teves línies més usuals
          si et registres i subscrius a les notificacions sobre els serveis que
          tu vulguis (nomès disponible per a usuaris registrats).
        </p>
      </v-col>
    </v-row>

    <v-spacer></v-spacer>

    <v-row v-if="errorMessages" justify="center">
      <v-alert dense type="error" class="my-2">
        Oops sembla que hi ha hagut algun problema per obtenir els avisos.<br />
        {{ errorMessages }}
      </v-alert>
    </v-row>

    <v-row v-if="isLoading" justify="center">
      <v-col cols="auto">
        <v-progress-circular
          indeterminate
          size="80"
          width="7"
          color="warning"
          class="ml-auto mr-auto"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row v-else align="start" justify="center" dense>
      <v-col cols="12">
        <v-responsive
          v-if="messages.length > 0"
          class="overflow-y-auto pa-md-4"
          max-height="auto"
          max-width="100%"
        >
          <v-alert
            v-for="(message, index) in messages"
            :key="index"
            border="top"
            colored-border
            :type="message.type.toString()"
            elevation="2"
          >
            <v-row dense justify-md="space-between">
              <h3 class="heading-6 pb-1">{{ message.title }}</h3>
              <v-col v-if="message.image" cols="12" md="7">
                <div v-html="message.message" class="pr-md-4"></div>
              </v-col>
              <v-col v-else-if="!message.image" cols="12" md="10">
                <div v-html="message.message" class="pr-md-3"></div>
              </v-col>
              <v-col
                cols="12"
                v-if="message.image"
                dense
                md="3"
                class="mx-0 px-0"
              >
                <v-img
                  :src="message.image"
                  contain
                  max-width="280"
                  max-height="350"
                />
              </v-col>
              <v-col cols="12" md="2" class="text-right">
                <h6 class="caption">VALIDESA</h6>
                <h5>
                  <span class="caption">Des de:</span>
                  {{ parseISO(message.since) | dateTimeEUformat }}
                </h5>
                <h5>
                  <span class="caption">Fins a:</span>
                  {{ parseISO(message.until) | dateTimeEUformat }}
                </h5>
              </v-col>
            </v-row>
          </v-alert>
        </v-responsive>
        <v-alert v-else style="info" elevation="2" prominent outlined dark>
          <h3 class="text-center green--text">
            En aquests moments no hi ha avisos per mostrar.
          </h3>
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { mapGetters } from "vuex";
// import Messages from "@/api/messages";
import { parseISO } from "date-fns";

export default {
  name: "Avisos",

  data: () => ({
    // messages: []
    errorMessages: null,
    isLoading: true,
    parseISO: parseISO
  }),

  computed: {
    messages() {
      return this.$store.getters.getMessages;
    }
  },

  methods: {
    setMessages() {
      this.isLoading = true;
      this.$store
        .dispatch("setMessages")
        .then(response => {
          console.log("Messages: " + response.data.length);
          this.isLoading = false;
        })
        .catch(error => {
          this.errorMessages = error;
          this.isLoading = false;
        });
    },
    formatDateTimeEs(value) {
      // Filtre global amb date-fns (vue-date-fns) definit a main.js
      return this.$dateTimeEUformat(value);
    }
  },

  created() {
    this.setMessages();
  }
};
</script>
